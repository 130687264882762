import dynamic from 'next/dynamic'

export type DynamicAddressIconName = keyof typeof dynamicAddressIcons

export const dynamicAddressIcons = {
  btc: dynamic(() => import('./BtcIcon.svg')),
  ltc: dynamic(() => import('./LtcIcon.svg')),
  doge: dynamic(() => import('./DogeIcon.svg')),
  rdd: dynamic(() => import('./RddIcon.svg')),
  dash: dynamic(() => import('./DashIcon.svg')),
  ppc: dynamic(() => import('./PpcIcon.svg')),
  via: dynamic(() => import('./ViaIcon.svg')),
  grs: dynamic(() => import('./GrsIcon.svg')),
  dgb: dynamic(() => import('./DgbIcon.svg')),
  dcr: dynamic(() => import('./DcrIcon.svg')),
  xem: dynamic(() => import('./XemIcon.svg')),
  sys: dynamic(() => import('./SysIcon.svg')),
  eth: dynamic(() => import('./EthIcon.svg')),
  icx: dynamic(() => import('./IcxIcon.svg')),
  xvg: dynamic(() => import('./XvgIcon.svg')),
  strat: dynamic(() => import('./StratIcon.svg')),
  ark: dynamic(() => import('./ArkIcon.svg')),
  atom: dynamic(() => import('./AtomIcon.svg')),
  zil: dynamic(() => import('./ZilIcon.svg')),
  egld: dynamic(() => import('./EgldIcon.svg')),
  zen: dynamic(() => import('./ZenIcon.svg')),
  xmr: dynamic(() => import('./XmrIcon.svg')),
  zec: dynamic(() => import('./ZecIcon.svg')),
  lsk: dynamic(() => import('./LskIcon.svg')),
  steem: dynamic(() => import('./SteemIcon.svg')),
  kmd: dynamic(() => import('./KmdIcon.svg')),
  xrp: dynamic(() => import('./XrpIcon.svg')),
  bch: dynamic(() => import('./BchIcon.svg')),
  xlm: dynamic(() => import('./XlmIcon.svg')),
  btg: dynamic(() => import('./BtgIcon.svg')),
  nano: dynamic(() => import('./NanoIcon.svg')),
  rvn: dynamic(() => import('./RvnIcon.svg')),
  eos: dynamic(() => import('./EosIcon.svg')),
  trx: dynamic(() => import('./TrxIcon.svg')),
  bcn: dynamic(() => import('./BcnIcon.svg')),
  neo: dynamic(() => import('./NeoIcon.svg')),
  algo: dynamic(() => import('./AlgoIcon.svg')),
  iost: dynamic(() => import('./IostIcon.svg')),
  divi: dynamic(() => import('./DiviIcon.svg')),
  iotx: dynamic(() => import('./IotxIcon.svg')),
  bts: dynamic(() => import('./BtsIcon.svg')),
  ckb: dynamic(() => import('./CkbIcon.svg')),
  luna: dynamic(() => import('./LunaIcon.svg')),
  dot: dynamic(() => import('./DotIcon.svg')),
  vsys: dynamic(() => import('./VsysIcon.svg')),
  abbc: dynamic(() => import('./AbbcIcon.svg')),
  near: dynamic(() => import('./NearIcon.svg')),
  etn: dynamic(() => import('./EtnIcon.svg')),
  aion: dynamic(() => import('./AionIcon.svg')),
  ksm: dynamic(() => import('./KsmIcon.svg')),
  ae: dynamic(() => import('./AeIcon.svg')),
  kava: dynamic(() => import('./KavaIcon.svg')),
  fil: dynamic(() => import('./FilIcon.svg')),
  ar: dynamic(() => import('./ArIcon.svg')),
  sol: dynamic(() => import('./SolIcon.svg')),
  xhv: dynamic(() => import('./XhvIcon.svg')),
  flow: dynamic(() => import('./FlowIcon.svg')),
  iris: dynamic(() => import('./IrisIcon.svg')),
  srm: dynamic(() => import('./SrmIcon.svg')),
  vlx: dynamic(() => import('./VlxIcon.svg')),
  tfuel: dynamic(() => import('./TfuelIcon.svg')),
  grin: dynamic(() => import('./GrinIcon.svg')),
  vet: dynamic(() => import('./VetIcon.svg')),
  bnb: dynamic(() => import('./BnbIcon.svg')),
  hive: dynamic(() => import('./HiveIcon.svg')),
  hnt: dynamic(() => import('./HntIcon.svg')),
  rune: dynamic(() => import('./RuneIcon.svg')),
  bcd: dynamic(() => import('./BcdIcon.svg')),
  one: dynamic(() => import('./OneIcon.svg')),
  ont: dynamic(() => import('./OntIcon.svg')),
  xtz: dynamic(() => import('./XtzIcon.svg')),
  ada: dynamic(() => import('./AdaIcon.svg')),
  qtum: dynamic(() => import('./QtumIcon.svg')),
  ela: dynamic(() => import('./ElaIcon.svg')),
  nas: dynamic(() => import('./NasIcon.svg')),
  hbar: dynamic(() => import('./HbarIcon.svg')),
  iota: dynamic(() => import('./IotaIcon.svg')),
  stx: dynamic(() => import('./StxIcon.svg')),
  nuls: dynamic(() => import('./NulsIcon.svg')),
  avax: dynamic(() => import('./AvaxIcon.svg')),
  ardr: dynamic(() => import('./ArdrIcon.svg')),
  wan: dynamic(() => import('./WanIcon.svg')),
  waves: dynamic(() => import('./WavesIcon.svg')),
  cro: dynamic(() => import('./CroIcon.svg')),
  go: dynamic(() => import('./GoIcon.svg')),
  etc: dynamic(() => import('./EtcIcon.svg')),
  tomo: dynamic(() => import('./TomoIcon.svg')),
  poa: dynamic(() => import('./PoaIcon.svg')),
  gno: dynamic(() => import('./GnoIcon.svg')),
  matic: dynamic(() => import('./MaticIcon.svg')),
  ewt: dynamic(() => import('./EwtIcon.svg')),
  ftm: dynamic(() => import('./FtmIcon.svg')),
  theta: dynamic(() => import('./ThetaIcon.svg')),
  clo: dynamic(() => import('./CloIcon.svg')),
  nrg: dynamic(() => import('./NrgIcon.svg')),
  celo: dynamic(() => import('./CeloIcon.svg')),
  op: dynamic(() => import('./OpIcon.svg')),
  arb1: dynamic(() => import('./ArbIcon.svg')),
  base: dynamic(() => import('./BaseIcon.svg')),
  linea: dynamic(() => import('./LineaIcon.svg')),
  strk: dynamic(() => import('./StrkIcon.svg')),
  zora: dynamic(() => import('./ZoraIcon.svg')),
  zksync: dynamic(() => import('./ZkSyncIcon.svg')),
  metis: dynamic(() => import('./MetisIcon.svg')),
  scr: dynamic(() => import('./ScrollIcon.svg')),
}
